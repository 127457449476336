<template>
  <div>
    <!-- Header -->
    <header class="py-5">
      <div class="container px-6 pb-5">

        <!-- Navigation -->
        <AppHeader />
        <div class="row gx-5 align-items-center">
          <div class="col-xxl-5">
            <!-- Header text content -->
            <div class="text-center text-xxl-start">
              <h1 class="display-3 fw-bolder mb-4">
                <span class="d-inline">We fight CoD false bans.</span>
              </h1>
              <h5 class="mb-4">
                We are dedicated to helping players who have been wrongfully banned from Call of Duty regain access to
                their accounts. We leverage specific legal frameworks to challenge unfair bans and ensure that your
                gaming experience
                isn't unjustly interrupted. If you believe you've been falsely banned, let us fight for you.
              </h5>
              <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                <router-link class="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" to="/approach"><i
                    class="fas fa-handshake"></i> How we can help</router-link>
                <router-link class="btn btn-outline-dark btn-lg px-5 py-3 fs-6 fw-bolder" to="/account/auth/register"><i
                    class="fa-solid fa-circle-right"></i> Submit case</router-link>

              </div>
            </div>
          </div>

          <div class="col-xxl-7">
            <!-- Header profile picture -->
            <div class="d-flex justify-content-center mt-5 mt-xxl-0">
              <img class="img-fluid" src="https://cdn.codfalseban.com/assets/images/23672996_6788198.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- About Section -->
    <section class="bg-body py-5">
      <div class="container mb-5"><!--
        <div class="alert alert-dismissible alert-warning mb-5">
          <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
          <strong><i class="fa-solid fa-circle-exclamation"></i> Our service is only available in the European Union
            (EU)</strong><br> Due to the specific legal frameworks we employ to contest bans which are primarily
          applicable within the EU jurisdiction, we can only help you if you are an EU resident.
        </div>-->

        <div class="row align-items-center text-center">

          <div class="inner-container-small text-center mb-4 mb-sm-5">
            <h1 class="fw-bold mb-4">Your Guide to Resolving False Bans</h1>
            <p class="mb-0">We help you understand and challenge unjust bans, offering guidance<br> and support beyond
              the standard appeal process.</p>
          </div>
        </div>
        <div class="row g-4 g-lg-6">
          <!-- Feature item -->
          <div class="col-xl-4">
            <div class="card card-body d-flex p-4 border h-100 flex-md-row flex-xl-column">
              <!-- Content -->
              <div class="me-md-5 me-xl-0 mb-4 mb-md-0">
                <!-- Icon -->
                <figure class="text-primary mb-3">
                  <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.74136 18.8326C9.60765 18.6411 9.54079 18.5453 9.48641 18.4446C9.36533 18.2205 9.2882 17.9753 9.25914 17.7222C9.24609 17.6085 9.24609 17.4917 9.24609 17.2582V14.1914H14.7461V17.2582C14.7461 19.1702 14.7461 20.1262 14.5249 20.5566C14.0158 21.5471 12.7956 21.931 11.8111 21.4103C11.3833 21.1841 10.836 20.4002 9.74136 18.8326Z"
                      fill="currentColor"></path>
                    <path
                      d="M17.5114 9.84101C19.375 6.88684 20.3067 5.40976 19.9484 4.21191C19.8359 3.83574 19.6441 3.48802 19.386 3.19214C18.5641 2.25 16.8176 2.25 13.3248 2.25H10.6405C7.12482 2.25 5.36697 2.25 4.54466 3.1974C4.28652 3.49482 4.09526 3.84421 3.98384 4.22194C3.6289 5.42518 4.57619 6.90596 6.47077 9.86751L9.23438 14.1875H14.7695L17.5114 9.84101Z"
                      fill="currentColor" fill-opacity="0.25"></path>
                  </svg>
                </figure>
                <h5 class="fw-bold card-title mb-3">Personalized case strategies </h5>
                <p class="mb-3">Our team develops personalized strategies tailored to your unique situation, ensuring
                  the best chance of success.
                </p>
              </div>
              <!-- Image -->
              <div class="mt-auto p-0">
                <img src="https://cdn.codfalseban.com/assets/images/6342523.webp" alt="feature-img" class="img-fluid">
              </div>
            </div>
          </div>

          <!-- Feature group items -->
          <div class="col-xl-8">
            <div class="row g-4 g-lg-6">
              <!-- Feature item -->
              <div class="col-12">
                <div class="card bg-primary bg-opacity-10 overflow-hidden p-5 pb-0"
                  style="    background-color: #E6F8ED !important;">
                  <div class="row g-4">
                    <div class="col-md-6">
                      <div class="card-body p-0">
                        <!-- Icon -->
                        <figure class="text-primary mb-3">
                          <svg width="50" height="50" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 2.25C8.20507 2.25 6.75 3.70507 6.75 5.5V6.25H13.25V5.5C13.25 3.70507 11.7949 2.25 10 2.25Z"
                              fill="currentColor"></path>
                            <path
                              d="M17.75 17.25H18.5C20.2949 17.25 21.75 15.7949 21.75 14C21.75 12.2051 20.2949 10.75 18.5 10.75H17.75V17.25Z"
                              fill="currentColor"></path>
                            <path
                              d="M4.5 12.25C5.4665 12.25 6.25 13.0335 6.25 14C6.25 14.9665 5.4665 15.75 4.5 15.75H3.53571C3.03452 15.75 2.78393 15.75 2.60098 15.865C2.50557 15.9249 2.4249 16.0056 2.36496 16.101C2.25 16.2839 2.25 16.5345 2.25 17.0357C2.25 18.8734 2.25 19.7923 2.67151 20.4631C2.89131 20.8129 3.1871 21.1087 3.53691 21.3285C4.20774 21.75 5.12659 21.75 6.96429 21.75C7.46548 21.75 7.71607 21.75 7.89903 21.635C7.99443 21.5751 8.0751 21.4944 8.13505 21.399C8.25 21.2161 8.25 20.9655 8.25 20.4643L8.25 19.5C8.25 18.5335 9.0335 17.75 10 17.75C10.9665 17.75 11.75 18.5335 11.75 19.5V20.4643C11.75 20.9655 11.75 21.2161 11.865 21.399C11.9249 21.4944 12.0056 21.5751 12.101 21.635C12.2839 21.75 12.5345 21.75 13.0357 21.75H13.25C14.8846 21.75 15.7019 21.75 16.3179 21.4136C16.7806 21.161 17.161 20.7806 17.4136 20.3179C17.75 19.7019 17.75 18.8846 17.75 17.25V10.75C17.75 9.1154 17.75 8.2981 17.4136 7.68207C17.161 7.21936 16.7806 6.83904 16.3179 6.58638C15.7019 6.25 14.8846 6.25 13.25 6.25H6.75C5.1154 6.25 4.2981 6.25 3.68206 6.58638C3.21936 6.83904 2.83904 7.21936 2.58638 7.68206C2.25 8.2981 2.25 9.1154 2.25 10.75V10.9643C2.25 11.4655 2.25 11.7161 2.36496 11.899C2.4249 11.9944 2.50557 12.0751 2.60098 12.135C2.78393 12.25 3.03452 12.25 3.53572 12.25H4.5Z"
                              fill="currentColor" fill-opacity="0.25"></path>
                          </svg>

                        </figure>
                        <!-- Title and content -->
                        <h5 class="fw-bold card-title mb-3">Managing your case shouldn't be complicated.</h5>
                        <p class="mb-3">We provide a dedicated panel that allows you to easily track and manage your
                          case, ensuring you stay informed
                          and in control every step of the way.</p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <!-- Image -->
                      <div class="me-n9 mb-n6">
                        <img src="https://cdn.codfalseban.com/assets/images/9319645_4133818.svg" class="card-img"
                          alt="feature-img">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Feature item -->
              <div class="col-lg-6">
                <div class="card bg-dark rounded h-100 overflow-hidden p-5">
                  <!-- SVG decoration -->
                  <figure class="position-absolute bottom-0 end-0 mb-n4 me-n5">
                    <svg class="fill-white opacity-1" width="220px" height="209px" viewBox="0 0 220 209"
                      style="enable-background:new 0 0 220 209;" xml:space="preserve">
                      <path
                        d="M84.3,120.6c-1.1-0.3-1.9-0.8-2.4-1.6c-1-1.4-0.7-3.3,0.8-5.2l15.9-21.1c2.2-2.9,6.5-5.3,10.6-5.8 c2.3-0.3,4.2,0,5.4,1l14.9,11.6c1,0.8,1.4,1.9,1.2,3.2c-0.6,3.1-4.6,6.5-9.3,8l-30.9,9.5C88.2,121,85.9,121.1,84.3,120.6z  M112.7,87.7c-1-0.3-2.1-0.3-3.4-0.1c-3.9,0.5-8.1,2.8-10.1,5.6l-15.9,21.1c-1.3,1.7-1.6,3.3-0.8,4.5c1.2,1.7,4.3,2,8,0.9 l30.8-9.5c5.2-1.6,8.4-5,8.9-7.5c0.2-1.1-0.1-2-1-2.6l-14.9-11.6C113.8,88.1,113.3,87.9,112.7,87.7z">
                      </path>
                      <path
                        d="M82.9,123.4c-1-0.3-1.8-0.7-2.4-1.4c-1.1-1.4-1-3.3,0.4-5.4l15.2-23.5c3.5-5.4,12.9-8.9,17-6.3l17.8,11.1 c1.2,0.8,1.8,1.9,1.6,3.3c-0.3,3.3-4.4,7.2-9.4,9l-32.9,12.4C87.5,123.6,84.9,123.9,82.9,123.4z M111.6,86.7 c-4.3-1.1-11.9,2.1-14.9,6.7l-15.2,23.5c-1.2,1.9-1.4,3.5-0.5,4.7c1.4,1.8,5.1,1.9,9,0.4l32.9-12.4c5.5-2.1,8.7-5.9,9-8.5 c0.1-1.2-0.3-2.1-1.4-2.7l-17.8-11.1C112.5,87,112.1,86.9,111.6,86.7z">
                      </path>
                      <path
                        d="M81.7,126.3c-0.9-0.3-1.7-0.7-2.3-1.3c-1.3-1.3-1.3-3.3,0-5.6l14.1-25.9c3.3-6,13.2-10.4,18-8l20.8,10.3 c1.4,0.7,2.2,1.9,2.2,3.5c-0.1,3.5-4,7.8-9.3,10.2l-34.9,15.6C87,126.5,83.9,126.9,81.7,126.3z M110.3,85.8 c-4.8-1.3-13.4,2.9-16.2,8.1l-14.1,25.9c-1.1,2-1.1,3.7-0.1,4.9c1.4,1.5,5.1,2.1,10.1-0.1l34.9-15.6c5.7-2.6,8.9-6.8,8.9-9.6 c0-1.3-0.6-2.3-1.8-2.9l-20.8-10.3C111,86,110.6,85.9,110.3,85.8z">
                      </path>
                      <path
                        d="M80.7,129.6c-0.9-0.2-1.6-0.6-2.2-1.2c-1.4-1.3-1.6-3.4-0.5-5.8l12.7-28.4c2.9-6.6,13.4-11.9,18.9-9.8 l24.1,9.3c1.7,0.7,2.7,1.9,2.8,3.6c0.2,3.7-3.6,8.4-9.1,11.3l-36.8,19.1C86.9,129.6,83.2,130.2,80.7,129.6z M108.8,84.8 c-5.3-1.4-14.9,3.7-17.5,9.7l-12.7,28.4c-1,2.1-0.8,4,0.4,5.1c1.7,1.6,5.9,2,11.3-0.8L127,108c5.9-3.1,9-7.8,8.8-10.7 c-0.1-1.4-0.9-2.5-2.4-3L109.3,85C109.2,84.9,109,84.9,108.8,84.8z">
                      </path>
                      <path
                        d="M79.9,133c-0.8-0.2-1.5-0.5-2.1-1c-1.6-1.3-2-3.4-1.1-5.9l10.8-30.9c2.5-7.1,13.4-13.6,19.7-11.8l27.5,7.9 c2,0.6,3.2,1.9,3.4,3.7c0.5,3.8-3.2,9.1-8.8,12.4l-38.3,23C87,132.9,82.8,133.8,79.9,133z M107,84c-6-1.6-16.5,4.7-18.8,11.4 l-10.8,30.9c-0.8,2.3-0.5,4.1,0.9,5.3c2.4,2,7.6,1.3,12.5-1.7l38.3-23c6.1-3.7,8.9-8.7,8.5-11.8c-0.2-1.6-1.2-2.7-3-3.2L107.2,84 C107.1,84,107.1,84,107,84z">
                      </path>
                      <path
                        d="M79.5,136.8c-0.7-0.2-1.3-0.5-1.9-0.9c-1.8-1.3-2.4-3.4-1.7-6.1l8.5-33.4c2-7.7,13.2-15.4,20.4-14l31.2,6.2 c2.3,0.5,3.8,1.8,4.2,3.8c0.8,4.1-2.6,9.6-8.4,13.6L92,133.3C87.5,136.4,82.8,137.7,79.5,136.8z M136.2,89.4 c-0.1,0-0.3-0.1-0.5-0.1l-31.2-6.2c-6.8-1.3-17.8,6.2-19.6,13.5L76.4,130c-0.6,2.4-0.1,4.3,1.5,5.4c2.9,2,8.6,0.9,13.8-2.6 l39.7-27.2c6.2-4.3,8.8-9.7,8.1-12.9C139.1,91,138,89.9,136.2,89.4z">
                      </path>
                      <path
                        d="M79.3,140.7c-0.6-0.2-1.1-0.4-1.6-0.7c-2-1.2-2.9-3.4-2.4-6.2L80.9,98c1.3-8.2,12.8-17.2,20.9-16.3l35.1,4.1 c2.7,0.3,4.5,1.7,5.1,3.9c1.2,4.2-2,10.3-7.8,14.7l-40.7,31.8C88.5,140,83,141.7,79.3,140.7z M136.7,86.2l0,0.3l-35.1-4.1 c-7.6-0.9-18.9,7.9-20.2,15.7l-5.6,35.8c-0.4,2.6,0.3,4.5,2.1,5.6c3.4,2,9.6,0.5,15-3.8l40.7-31.7c5.6-4.3,8.7-10.1,7.6-14.1 c-0.5-2-2.1-3.2-4.6-3.4L136.7,86.2z">
                      </path>
                      <path
                        d="M79.5,144.9c-0.4-0.1-0.9-0.3-1.3-0.5c-2.3-1.1-3.4-3.3-3.3-6.3l2.2-38.2c0.5-8.8,12.2-19.1,21.2-18.8 l39.1,1.6c3.1,0.1,5.3,1.5,6.1,3.9c1.5,4.3-1.4,10.8-7,15.9l-41.3,36.6C90,143.8,83.8,146,79.5,144.9z M137.4,83.1l0,0.3 l-39.1-1.6c-8.5-0.3-20,9.8-20.5,18.2l-2.2,38.2c-0.2,2.7,0.8,4.7,2.9,5.7c4,1.9,10.7-0.2,16.3-5.1l41.3-36.6 c5.5-4.9,8.3-11.1,6.8-15.2c-0.8-2.2-2.7-3.4-5.5-3.5L137.4,83.1z">
                      </path>
                      <path
                        d="M80.2,149.2c-0.3-0.1-0.6-0.2-0.8-0.3c-2.6-1-4.1-3.2-4.2-6.3l-1.8-40.4c-0.2-4.1,2-9,6.1-13.3 c4.5-4.8,10.3-7.9,15.1-8.1l43.2-1.3c3.5-0.1,6.1,1.3,7.3,3.9c2,4.5-0.5,11.3-6.1,17l-41.4,41.8C92,147.8,85.1,150.5,80.2,149.2z  M140.6,80.4c-0.8-0.2-1.7-0.3-2.7-0.3l-43.2,1.3c-4.7,0.1-10.3,3.1-14.7,7.9c-3.9,4.2-6.1,9-5.9,12.9l1.8,40.4 c0.1,2.8,1.4,4.8,3.8,5.7c4.6,1.8,11.8-0.9,17.5-6.6l41.4-41.8c5.3-5.4,7.8-12.1,6-16.3C143.8,82.1,142.4,80.9,140.6,80.4z">
                      </path>
                      <path
                        d="M81.4,153.8c-0.1,0-0.1,0-0.2,0c-2.9-0.8-4.8-3.1-5.3-6.3L69.5,105c-0.6-4.2,1.1-9.3,4.8-14 c4.4-5.7,10.6-9.6,16.1-10.1l47.5-4.8c4-0.4,7,1,8.5,3.9c2.4,4.6,0.4,11.9-5,18.1l-41,47.3C94.7,151.8,86.9,155.3,81.4,153.8z  M137.9,76.4l0,0.3l-47.5,4.8c-5.4,0.5-11.4,4.3-15.7,9.9c-3.6,4.6-5.3,9.5-4.7,13.6l6.5,42.5c0.5,3,2.1,5,4.8,5.8 c5.3,1.5,12.9-1.9,18.5-8.3l41-47.2c5.2-6,7.2-13,4.9-17.3c-1.4-2.6-4.2-3.9-7.9-3.5L137.9,76.4z">
                      </path>
                      <path
                        d="M83.2,158.5c-3-0.8-5-2.9-5.9-6.1l-11.8-44.3c-1.1-4.2,0.1-9.6,3.5-14.7c4.2-6.6,10.7-11.3,16.9-12.4 l51.8-8.7c4.5-0.8,8.1,0.6,10,3.7c2.9,4.7,1.4,12.3-3.7,19.1L104,148.1c-5.6,7.4-13.9,11.7-20.1,10.5 C83.6,158.6,83.4,158.5,83.2,158.5z M142.6,73.1c-1.4-0.4-3.1-0.4-4.9-0.1l-51.8,8.7c-6,1-12.4,5.6-16.5,12.1 c-3.2,5-4.5,10.2-3.4,14.2l11.8,44.3c0.9,3.2,2.9,5.2,6.1,5.8c6,1.2,14-3,19.5-10.2l39.9-52.9c4.9-6.5,6.4-13.9,3.7-18.4 C146.1,74.7,144.5,73.6,142.6,73.1z">
                      </path>
                      <path
                        d="M85.6,163.2c-2.9-0.8-5.1-2.8-6.3-5.7l-18-45.7c-1.7-4.2-0.9-9.8,2-15.4c3.9-7.3,10.7-13.1,17.5-14.7 l56.1-13.2c5-1.2,9.3,0.1,11.7,3.5c3.4,4.8,2.5,12.7-2.2,20L108.2,151c-5.3,8.1-13.8,13.3-20.8,12.6 C86.7,163.5,86.2,163.4,85.6,163.2z M137,68.8l0.1,0.3L80.9,82.3c-6.6,1.6-13.3,7.2-17,14.4c-2.8,5.4-3.5,10.8-1.9,14.9l18,45.7 c1.3,3.3,3.9,5.3,7.5,5.6c6.8,0.7,15.1-4.4,20.2-12.3l38.1-58.9c4.6-7.1,5.5-14.7,2.3-19.3c-2.3-3.2-6.2-4.4-11-3.2L137,68.8z">
                      </path>
                      <path
                        d="M88.7,168.1c-2.9-0.8-5.1-2.6-6.6-5.3L57.3,116c-2.3-4.3-2.1-10.2,0.4-16.2C61,91.7,68,85,75.3,82.7 l60.3-18.2c5.6-1.7,10.5-0.5,13.5,3.1c4,4.8,3.7,13.1-0.5,20.9l-35.4,65c-4.8,8.8-13.5,14.9-21.2,14.9 C90.8,168.5,89.7,168.3,88.7,168.1z M135.8,65.1L75.5,83.3c-7.2,2.2-14,8.7-17.3,16.7c-2.4,5.8-2.6,11.5-0.4,15.6l24.9,46.8 c1.8,3.5,5.1,5.4,9.2,5.4c7.5,0,16-6,20.7-14.6l35.4-65c4.2-7.6,4.4-15.6,0.6-20.2C145.8,64.6,141.1,63.5,135.8,65.1z">
                      </path>
                      <path
                        d="M92.6,172.9c-2.8-0.7-5.1-2.4-6.8-4.8l-32.6-47.4c-3-4.3-3.5-10.5-1.4-17c2.8-8.9,9.7-16.5,17.6-19.4 l64.4-23.9c6.2-2.3,11.8-1.3,15.5,2.6c4.6,4.9,5.1,13.2,1.4,21.7L118.9,156c-4.3,9.6-12.8,16.5-21.3,17.3 C95.8,173.4,94.1,173.3,92.6,172.9z M143.5,60.2c-2.8-0.8-6.1-0.5-9.5,0.7L69.6,84.9c-7.7,2.9-14.5,10.3-17.2,19 c-2,6.3-1.5,12.3,1.3,16.4l32.6,47.4c2.5,3.6,6.5,5.4,11.2,5c8.2-0.7,16.6-7.6,20.7-16.9l31.8-71.3c3.7-8.2,3.2-16.3-1.2-21 C147.4,61.9,145.6,60.8,143.5,60.2z">
                      </path>
                      <path
                        d="M97.3,177.7c-2.6-0.7-5-2.1-6.8-4.3l-41.2-47.4c-3.8-4.3-5-10.8-3.4-17.8C48,98.6,54.8,90,63.1,86.4 l68.3-30.1c6.7-3,13.2-2.3,17.7,1.9c5.3,4.9,6.6,13.5,3.5,22.4l-27.1,77.6c-3.5,10.1-11.9,18.1-20.9,19.8 C102,178.3,99.5,178.3,97.3,177.7z M131.5,56.5l0.1,0.3L63.3,86.9c-8.1,3.6-14.7,12-16.8,21.3c-1.5,6.8-0.3,13.1,3.3,17.3 L90.9,173c3.3,3.8,8.1,5.3,13.5,4.3c8.8-1.7,17-9.5,20.4-19.4L152,80.3c3-8.7,1.8-17.1-3.4-21.8c-4.3-4-10.5-4.6-17-1.7 L131.5,56.5z">
                      </path>
                      <path
                        d="M102.9,182.3c-2.5-0.7-4.7-1.9-6.7-3.7l-50.6-46.8c-4.7-4.3-6.7-11.2-5.7-18.8c1.4-10.1,7.8-19.5,16.5-24 L128.2,52c7.3-3.7,14.6-3.4,20,0.9c6.1,4.8,8.3,13.7,6,23.1L133,159.7c-2.7,10.7-10.6,19.4-20.1,22.2 C109.3,183,105.9,183.1,102.9,182.3z M128.4,52.2l0.1,0.3L56.6,89.6c-8.5,4.4-14.8,13.6-16.2,23.5c-1,7.4,1,14.1,5.5,18.2 l50.6,46.8c4.2,3.9,10,5.1,16.1,3.3c9.3-2.7,17-11.3,19.6-21.8l21.3-83.8c2.3-9.2,0.1-17.7-5.7-22.4c-5.2-4.2-12.3-4.5-19.3-0.8 L128.4,52.2z">
                      </path>
                      <path
                        d="M109.3,186.7c-2.2-0.6-4.3-1.6-6.2-3l-61-45.4c-5.7-4.3-8.8-11.5-8.3-19.8c0.6-10.7,6.5-20.7,15.5-26.1 l75.1-44.6c7.7-4.6,16.1-4.7,22.5-0.3c7,4.7,10.3,13.5,8.7,23.6l-14.1,90c-1.7,11-9.1,20.7-18.7,24.6 C118.1,187.4,113.4,187.8,109.3,186.7z M124.4,48l0.2,0.3L49.5,92.9c-8.8,5.2-14.6,15-15.2,25.6c-0.4,8.1,2.5,15.1,8.1,19.3 l61,45.4c5.3,3.9,12.2,4.6,19,1.9c9.4-3.9,16.7-13.3,18.4-24.2l14.1-90c1.5-9.8-1.6-18.3-8.4-22.9c-6.2-4.2-14.4-4.1-21.9,0.4 L124.4,48z">
                      </path>
                      <path
                        d="M116.6,190.8c-1.9-0.5-3.7-1.3-5.5-2.3l-72.2-43.1c-7-4.2-11.1-11.8-11.4-20.9c-0.3-11.1,5.1-21.8,14.2-28 l77.8-52.8c8.1-5.5,17.5-6.2,25.1-1.9c8,4.5,12.4,13.4,11.8,23.9l-5.5,95.9c-0.7,11.4-7.1,21.7-16.9,26.9 C128.2,191.5,122.2,192.2,116.6,190.8z M119.6,43.9l0.2,0.3L42,97c-8.9,6-14.2,16.6-13.9,27.5c0.3,8.9,4.3,16.3,11.1,20.4 l72.2,43.1c6.6,3.9,14.7,4,22.2,0c9.6-5.1,15.9-15.2,16.6-26.4l5.5-95.9c0.6-10.2-3.7-18.9-11.5-23.3c-7.4-4.2-16.6-3.5-24.5,1.9 L119.6,43.9z">
                      </path>
                      <path
                        d="M124.9,194.4c-1.5-0.4-2.9-0.9-4.3-1.6L36.2,153c-8.4-4-13.9-12-15-22c-1.3-11.4,3.6-22.8,12.6-29.8 l79.8-61.7c8.4-6.5,18.8-7.9,27.7-3.8c9.1,4.2,14.8,13.2,15.3,24.1l4.6,101.5c0.5,11.6-5.1,22.7-14.6,29 C139.9,195,132.1,196.4,124.9,194.4z M137.2,35c-7.7-2.1-16.1-0.2-23.1,5.2l-79.8,61.7c-8.8,6.8-13.6,18-12.4,29.2 c1.1,9.8,6.4,17.6,14.6,21.5l84.4,39.8c8.2,3.8,17.4,3,25.5-2.4c9.3-6.2,14.8-17.1,14.3-28.5L156.1,60 c-0.5-10.6-6.1-19.5-14.9-23.6C139.9,35.8,138.5,35.3,137.2,35z">
                      </path>
                      <path
                        d="M134,197.6c-0.8-0.2-1.6-0.5-2.5-0.8l-97.4-35.4c-9.9-3.6-17.1-12.2-19.1-23c-2.2-11.7,1.8-23.4,10.8-31.3 l81.1-71.2c8.6-7.6,19.9-9.8,30.3-6.1c10.4,3.8,17.6,12.8,19.3,24.1l16.3,106.6c1.8,11.8-2.6,23.4-11.9,30.9 C153.2,197.8,143.2,200,134,197.6z M134.5,29.6c-9.5-2.5-19.5-0.1-27.2,6.7l-81.1,71.2c-8.8,7.7-12.8,19.2-10.6,30.7 c2,10.6,9,19,18.7,22.5l97.4,35.4c9.7,3.5,20.5,1.6,28.8-5.2c9.1-7.4,13.4-18.7,11.6-30.3L155.9,54c-1.7-11.1-8.8-19.9-18.9-23.6 C136.2,30.1,135.3,29.8,134.5,29.6z">
                      </path>
                      <path
                        d="M144,200.1L32.8,170.3C21,167.1,12,158.2,8.9,146.4c-3.2-11.8,0.1-24,8.7-32.6L99,32.4 c8.7-8.6,20.9-11.9,32.7-8.7c11.8,3.2,20.8,12.1,23.9,23.9l29.8,111.1c3.2,11.8-0.1,24-8.7,32.6C168,200,155.8,203.3,144,200.1z  M99.2,32.6l0.2,0.2L18,114.2c-8.5,8.5-11.7,20.5-8.6,32c3.1,11.6,11.9,20.3,23.5,23.5l111.2,29.8c11.6,3.1,23.6-0.1,32.1-8.6 c8.5-8.5,11.7-20.5,8.6-32L155,47.7c-3.1-11.6-11.9-20.4-23.5-23.5c-11.6-3.1-23.6,0.1-32.1,8.6L99.2,32.6z">
                      </path>
                    </svg>
                  </figure>

                  <!-- Card body -->
                  <div class="card-body bg-transparent p-0">
                    <h5 class="fw-bold text-white mb-0">Want to learn more about us and the reasons behind starting this
                      service?</h5>
                  </div>

                  <!-- Card footer -->
                  <div class="card-footer bg-transparent p-0 mt-6" style="border: unset;">
                    <router-link class="btn btn-light mb-0" to="/about-us"><i class="fa fa-info"></i> About
                      us</router-link>
                  </div>
                </div>
              </div>

              <!-- Feature item -->
              <div class="col-lg-6">
                <div class="card bg-light rounded h-100 overflow-hidden p-4">
                  <!-- Card body -->
                  <div class="card-body bg-transparent p-0">
                    <!-- Icon SVG -->
                    <figure class="text-primary">
                      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.95 9.83377C3.51099 9.1789 2.79149 8.85147 2.55187 8.40828C2.3112 7.96313 2.3112 7.42663 2.55187 6.98148C2.79149 6.53829 3.51099 6.21086 4.95 5.55599L10.8816 2.8566C11.37 2.63435 11.6142 2.52322 11.872 2.50115C11.9572 2.49386 12.0428 2.49386 12.128 2.50115C12.3858 2.52322 12.63 2.63435 13.1184 2.8566L19.05 5.55599C20.489 6.21086 21.2085 6.53829 21.4481 6.98148C21.6888 7.42663 21.6888 7.96313 21.4481 8.40828C21.2085 8.85147 20.489 9.1789 19.05 9.83377L13.1184 12.5332C12.63 12.7554 12.3858 12.8665 12.128 12.8886C12.0428 12.8959 11.9572 12.8959 11.872 12.8886C11.6142 12.8665 11.37 12.7554 10.8816 12.5332L4.95 9.83377Z"
                          fill="currentColor" fill-opacity="0.25"></path>
                        <path
                          d="M4.95 9.83377C3.51099 10.4886 2.79149 10.8161 2.55187 11.2593C2.3112 11.7044 2.3112 12.2409 2.55187 12.6861C2.79149 13.1292 3.51099 13.4567 4.95 14.1115L10.8816 16.8109C11.37 17.0332 11.6142 17.1443 11.872 17.1664C11.9572 17.1737 12.0428 17.1737 12.128 17.1664C12.3858 17.1443 12.63 17.0332 13.1184 16.8109L19.05 14.1115C20.489 13.4567 21.2085 13.1292 21.4481 12.6861C21.6888 12.2409 21.6888 11.7044 21.4481 11.2593C21.2085 10.8161 20.489 10.4886 19.05 9.83377L13.1184 12.5332C12.63 12.7554 12.3858 12.8665 12.128 12.8886C12.0428 12.8959 11.9572 12.8959 11.872 12.8886C11.6142 12.8665 11.37 12.7554 10.8816 12.5332L4.95 9.83377Z"
                          fill="currentColor" fill-opacity="0.25"></path>
                        <path
                          d="M4.95 14.1115C3.51099 14.7664 2.79149 15.0938 2.55187 15.537C2.3112 15.9822 2.3112 16.5187 2.55187 16.9638C2.79149 17.407 3.51099 17.7345 4.95 18.3893L10.8816 21.0887C11.37 21.311 11.6142 21.4221 11.872 21.4442C11.9572 21.4515 12.0428 21.4515 12.128 21.4442C12.3858 21.4221 12.63 21.311 13.1184 21.0887L19.05 18.3893C20.489 17.7345 21.2085 17.407 21.4481 16.9638C21.6888 16.5187 21.6888 15.9822 21.4481 15.537C21.2085 15.0938 20.489 14.7664 19.05 14.1115L13.1184 16.8109C12.63 17.0332 12.3858 17.1443 12.128 17.1664C12.0428 17.1737 11.9572 17.1737 11.872 17.1664C11.6142 17.1443 11.37 17.0332 10.8816 16.8109L4.95 14.1115Z"
                          fill="currentColor" fill-opacity="0.25"></path>
                        <path
                          d="M6 10.2734L10.8827 12.4929C11.3707 12.7146 11.6146 12.8255 11.8722 12.8476C11.9572 12.8548 12.0428 12.8548 12.1278 12.8476C12.3854 12.8255 12.6293 12.7146 13.1173 12.4929L18 10.2734M6 14.2734L10.8827 16.4929C11.3707 16.7146 11.6146 16.8255 11.8722 16.8476C11.9572 16.8548 12.0428 16.8548 12.1278 16.8476C12.3854 16.8255 12.6293 16.7146 13.1173 16.4929L18 14.2734"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </figure>
                    <!-- Title -->
                    <h5 class="fw-bold mb-3">Dedicated support team</h5>
                    <p class="mb-0">Our dedicated support team is always available to answer your questions and provide
                      assistance whenever you need it.</p>
                  </div>

                  <!-- Card footer -->
                  <div class="card-footer bg-transparent p-0 mt-6" style="border: unset;">
                  </div>
                </div>
              </div>
            </div> <!-- Row END -->
          </div>
        </div>
      </div>

    </section>
    <section class="bg-body py-5">
      <div class="container">
        <div class="border-top mx-auto mb-5" style="max-width: 25rem;"></div>
        <div class="inner-container-small text-center mb-4 mb-sm-6">
          <span class="text-primary fw-bold text-uppercase">YOUR RIGHTS</span>
          <h1 class="fw-bold mb-0 mt-3">Fundamental regulations aimed at<br> protecting consumers</h1>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-6 mb-4 d-flex">
            <div style="border: unset;" class="card mb-3 shadow flex-fill">
              <h3
                style="background: linear-gradient(to bottom right, #164e93, #4373cb); color: white; border-color: #ff000000; border-top-left-radius: 20px; border-top-right-radius: 20px;"
                class="card-header text-center fw-bold">GDPR<br>
                <span style="font-size: 17px;">General Data Protection Regulation</span>
              </h3>

              <div class="card-body">
                <h5 class="card-title">Your rights in the context of a false ban.</h5>
                <h6 class="card-subtitle text-muted">The GDPR gives you the right to access all the information that
                  Activision has
                  about you, including the specific reasons for your ban.
                  This clarity is essential for understanding why the ban occurred and for making a solid case if you
                  believe it was unwarranted.</h6>
              </div>
              <div class="card-body mb-3">

                <ul style="list-style-type: none; padding: 0;">
                  <li>✅ <b>Right to Access Information <i class="bi bi-arrow-right-short"></i></b> You have the right to
                    access the specific details related to your
                    ban, enabling you to understand and potentially challenge the decision.</li>
                  <li>❌ <b>Lack of Transparency <i class="bi bi-arrow-right-short"></i></b> Activision does not provide
                    clear and detailed explanations for bans,
                    making it difficult for players to understand the reasons and challenge them effectively.</li>
                  <li>❌ <b>Limited Access to Data <i class="bi bi-arrow-right-short"></i></b> Players face obstacles in
                    accessing the specific data Activision
                    holds about them, hindering their ability to assess the fairness of the ban.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4 d-flex">
            <div style="border: unset;" class="card mb-3 shadow flex-fill">
              <h3
                style="background: linear-gradient(to bottom right, #5619a7, #7b2cd1); color: white; border-color: #ff000000; border-top-left-radius: 20px; border-top-right-radius: 20px;"
                class="card-header text-center fw-bold">UCPD<br>
                <span style="font-size: 17px;">Unfair Commercial Practices Directive</span>
              </h3>

              <div class="card-body">
                <h5 class="card-title">Your rights in the context of a false ban.</h5>
                <h6 class="card-subtitle text-muted">The UCPD focuses on ensuring fairness and preventing misleading
                  practices in
                  consumer transactions. This is particularly relevant in cases of false
                  bans, as it highlights the power imbalance where companies like Activision hold significant control
                  over user accounts.</h6>
              </div>
              <div class="card-body mb-3">
                <br>
                <ul style="list-style-type: none; padding: 0;">
                  <li>✅ <b>Right to Fair Treatment <i class="bi bi-arrow-right-short"></i></b> You have the right to be
                    treated fairly and not subjected to misleading
                    or deceptive practices by game publishers.</li>
                  <li>❌ <b>Incorrect or Misleading Information: <i class="bi bi-arrow-right-short"></i></b> If
                    Activision's reasoning for the ban is based on incorrect or inaccurate information, it could
                    constitute a misleading practice.</li>
                  <li>❌ <b>Aggressive Communication <i class="bi bi-arrow-right-short"></i></b> Activision's
                    communication regarding bans can be perceived as
                    aggressive or intimidating, discouraging players from seeking further information or challenging the
                    decision.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="alert alert-dismissible alert-primary">
            <strong><i class="fa-solid fa-lightbulb"></i> Want to learn more?</strong><br>
            We explain both regulations and their interpretation regarding false bans in more detail at
            <router-link to="/blog"><span class="badge rounded-pill bg-light" style="color:black;"><i
                  class="fa-solid fa-arrow-right-from-bracket"></i> codfalseban.com/blog</span></router-link>

          </div>
        </div>


      </div>
    </section>


  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
  components: {
    AppHeader
  }
}
</script>

<style scoped>
/* Add any component-specific styles here */
.icon-md {
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  font-size: 0.8rem;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(9, 184, 80, var(--bs-bg-opacity)) !important;
}
</style>
