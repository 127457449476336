<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Blog</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Tips for navigating the complexities of account suspensions.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-8" v-for="article in articles" :key="article.id">
                        <h1 class="fw-bold">{{ article.title }}</h1>
                        <p class="text-muted" style="font-size: 0.9em;">
                            <i class="fas fa-calendar-alt"></i> Posted on {{ article.date }}
                            <span style="margin: 0 5px;"></span>
                            <i class="fas fa-clock"></i> Read time: {{ article.read_time }}
                        </p>
                        <hr>
                        <div style="width: 100%;">
                            <img class="mt-3" :src="article.image" style="width: 100%; height: auto;">
                        </div>

                        <p class="mt-4 fw-bold" v-html="article.content"></p>
                        <router-link :to="{ name: 'ArticleDetail', params: { title: formatTitle(article.title) } }" class="btn btn-warning mt-3">
                            <i class="fas fa-arrow-right"></i>
                            Continue reading
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import { ref, onMounted } from 'vue';

export default {
    components: {
        AppHeader
    },
    setup() {
        const articles = ref([]);

        const fetchArticles = async () => {
            try {
                const response = await fetch('https://api.codfalseban.com/v1/blog/articles.json');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const fetchedArticles = await response.json();
                // Clean up article titles to remove single quotes
                articles.value = fetchedArticles.map(article => ({
                    ...article,
                    title: article.title // Keep the original title for display
                }));
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        const formatTitle = (title) => {
            return title
                .replace(/'/g, '') // Remove single quotes
                .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove unwanted characters
                .toLowerCase()
                .replace(/\s+/g, '-'); // Replace spaces with dashes
        };

        onMounted(fetchArticles);

        return {
            articles,
            formatTitle
        };
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
