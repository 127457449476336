<template>
  <div>
    <!-- Header -->
    <header class="py-5">
      <div class="container px-6 pb-5">
        <!-- Navigation -->
        <AppHeader />
      </div>
      <div class="container px-5">
        <div class="row gx-5 align-items-center text-center">
          <h1 class="lh-1 mb-3" style="font-weight: 800;">Register</h1>
          <p class="lead fw-normal text-muted mb-5">
            Create your account to submit your case.
          </p>
        </div>
      </div>
    </header>
    <!-- Registration Section -->
    <section class="bg-body py-5">
      <div class="container mb-5" style="margin-top: -97px;">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <!-- Display errors if any -->
                <div v-if="errors.length" class="alert alert-danger" role="alert">
                  <b><i class="fa-solid fa-circle-exclamation"></i> The following error has occurred:</b>
                  <ul style="margin: 0; padding: 0 0 0 20px; list-style:none;">
                    <li v-for="(error, index) in errors" :key="index" style="margin: 0; padding: 0;">{{ error }}</li>
                  </ul>
                </div>

                <!-- Registration Form -->
                <form @submit.prevent="handleSubmit">
                  <h3 class="fw-bold">Your personal details</h3>
                  <h5>This information will be utilized in our communications with Activision on your behalf.</h5>
                  <div class="mb-3">
                    <label for="name" class="form-label">Firstname</label>
                    <input type="text" class="form-control" id="name" v-model="name" required />
                  </div>
                  <div class="mb-3">
                    <label for="surname" class="form-label">Surname</label>
                    <input type="text" class="form-control" id="surname" v-model="surname" required />
                  </div>

                  <h3 class="fw-bold">Your login details</h3>
                  <h5>Please enter an email and password.</h5>
                  <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="email" v-model="email" required />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" class="form-control" id="password" v-model="password"
                      @input="validatePassword" required />
                    <div id="password-requirements" class="mt-2">
                      <p id="length"
                        :class="{ 'text-success': passwordValid.length, 'text-danger': !passwordValid.length }">
                        <i class="bi bi-x-circle" v-if="!passwordValid.length"></i>
                        <i class="bi bi-check-circle" v-if="passwordValid.length"></i> Minimum 8 characters without
                        spaces
                      </p>
                      <p id="letter"
                        :class="{ 'text-success': passwordValid.letter, 'text-danger': !passwordValid.letter }">
                        <i class="bi bi-x-circle" v-if="!passwordValid.letter"></i>
                        <i class="bi bi-check-circle" v-if="passwordValid.letter"></i> Minimum 1 letter
                      </p>
                      <p id="number"
                        :class="{ 'text-success': passwordValid.number, 'text-danger': !passwordValid.number }">
                        <i class="bi bi-x-circle" v-if="!passwordValid.number"></i>
                        <i class="bi bi-check-circle" v-if="passwordValid.number"></i> At least 1 number or punctuation
                        mark (e.g. !@#$)
                      </p>
                    </div>
                  </div>
                  <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" id="tos" v-model="tos" required />
                    <label class="form-check-label" for="tos">
                      I agree to the <router-link to="/tos">Terms of Service</router-link> and <router-link
                        to="/privacy">Privacy Statement</router-link>
                    </label>
                  </div>
                  <button type="submit" class="btn btn-success"><i class="fa-solid fa-arrow-right-to-bracket"></i> Register your account</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import AppHeader from '@/components/app/AppHeader.vue';

export default {
  components: {
    AppHeader
  },
  setup() {
    const router = useRouter();
    const session = inject('session'); // Inject session data from the root component

    // Form state
    const name = ref('');
    const surname = ref('');
    const email = ref('');
    const password = ref('');
    const tos = ref(false);
    const errors = ref([]);
    const passwordValid = ref({
      length: false,
      letter: false,
      number: false
    });

    // Validate password requirements
    const validatePassword = () => {
      const passwordValue = password.value;
      const minLengthPattern = /.{8,}/;
      const letterPattern = /[a-zA-Z]/;
      const numberOrPunctuationPattern = /[\d\W]/;

      // Check length
      passwordValid.value.length = minLengthPattern.test(passwordValue);

      // Check for letter
      passwordValid.value.letter = letterPattern.test(passwordValue);

      // Check for number or punctuation
      passwordValid.value.number = numberOrPunctuationPattern.test(passwordValue);
    };

    // Handle form submission for registration
    const handleSubmit = async () => {
      errors.value = []; // Clear previous errors

      if (!passwordValid.value.length || !passwordValid.value.letter || !passwordValid.value.number) {
        errors.value.push('Password does not meet the requirements.');
        return;
      }

      if (!tos.value) {
        errors.value.push('You need to agree with the Terms of Service.');
        return;
      }

      try {
        const response = await fetch('https://api.codfalseban.com/v1/auth/user/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: name.value,
            surname: surname.value,
            email: email.value,
            password: password.value
          }),
          credentials: 'include'
        });

        if (response.ok) {
          const result = await response.json();
          if (result.status === 'success') {
            // Attempt to log in after successful registration
            const loginResponse = await fetch('https://api.codfalseban.com/v1/auth/user/dologin', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: email.value,
                password: password.value
              }),
              credentials: 'include'
            });

            if (loginResponse.ok) {
              const loginResult = await loginResponse.json();
              if (loginResult.status === 'success') {
                // Set session data
                session.isLoggedIn = true;
                session.fullName = `${loginResult.firstName} ${loginResult.surname}`;
                session.firstName = `${loginResult.firstName}`;
                session.surname = `${loginResult.surname}`;

                // Redirect to account overview
                router.push('/account/overview');
              } else {
                errors.value = loginResult.message.split(', '); // Handle server-side login errors
              }
            } else {
              throw new Error(`HTTP error during login! Status: ${loginResponse.status}`);
            }
          } else {
            errors.value = result.message.split(', '); // Handle server-side registration errors
          }
        } else {
          throw new Error(`HTTP error during registration! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error during registration:', error);
        errors.value.push(`(${error.message})`);
      }
    };

    return {
      name,
      surname,
      email,
      password,
      tos,
      errors,
      passwordValid,
      handleSubmit,
      validatePassword
    };
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
#password-requirements p {
  font-size: 14px;
  margin: 0;
}

#password-requirements .text-success {
  color: #2ecc71;
  font-weight: bold;
}

#password-requirements .text-danger {
  color: black !important;
}

#password-requirements i {
  margin-right: 8px;
}

.benefits {
  background-color: #ffffff;
  padding: 20px;
}

.benefits h4 {
  margin-bottom: 15px;
  font-weight: bold;
}

.benefits ul {
  list-style-type: none;
  padding: 0;
}

.benefits ul li {
  margin-bottom: 10px;
}

.benefits ul li i {
  color: #2ecc71;
  margin-right: 8px;
}
</style>