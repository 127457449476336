<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Blog</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Tips for navigating the complexities of account suspensions.
                    </p>
                </div>
            </div>
        </header>

        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-8">
                        <h1 class="fw-bold">{{ article.title }}</h1>
                        <p class="text-muted" style="font-size: 0.9em;">
                            <i class="fas fa-calendar-alt"></i> Posted on {{ article.date }}
                            <span style="margin: 0 5px;"></span>
                            <i class="fas fa-clock"></i> Read time: {{ article.read_time }}
                        </p>
                        <hr>
                        <div style="width: 100%;">
                            <img class="mt-3" :src="article.image" style="width: 100%; height: auto;">
                        </div>
                        <p class="mt-4 fw-bold" v-html="article.content"></p>
                        <p class="mt-4" v-html="article.full_content"></p>

                        <router-link to="/blog" class="btn btn-success mt-3">
                            <i class="fas fa-arrow-right"></i>
                            Go back</router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router'; // Import useRoute
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader // Register the component
    },
    setup() {
        const article = ref({});
        const route = useRoute(); // Get the current route

        const fetchArticle = async (title) => {
            try {
                const response = await fetch('https://api.codfalseban.com/v1/blog/articles.json');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const articles = await response.json();

                // Clean up titles before matching
                article.value = articles.find(a => a.title.replace(/'/g, '').replace(/[^a-zA-Z0-9\s-]/g, '').toLowerCase().replace(/\s+/g, '-') === title);

                // Update document title if article is found
                if (article.value && article.value.title) {
                    document.title = `${article.value.title} - Call of Duty False Bans`; // Set the document title
                } else {
                    // If not found, redirect to /blog
                    window.location.href = '/blog';
                }
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        onMounted(() => {
            const title = route.params.title; // Use route.params.title
            fetchArticle(title);
        });

        return {
            article
        };
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
